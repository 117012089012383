import {
  Component,
  EventEmitter,
  HostBinding, Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import {MatInput, MatInputModule} from "@angular/material/input";
import {CodeToFlagEmoji} from "@core/helpers/flags.helpers";
import {MatOptionModule} from "@angular/material/core";
import {IndicatifTelephoniqueResource} from "@core/backend/models/referentiel";
import {MatFormFieldControl} from "@angular/material/form-field";
import {NgControl, AbstractControlDirective} from '@angular/forms';
import { Subject } from "rxjs";
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

export interface SaisieTelephoneDefaultOptions {
  placeholders?: {
    indicatif?: string,
    telephone?: string
  }
}

export const CORE_SAISIE_TELEPHONE_DEFAULT_OPTIONS = new InjectionToken<SaisieTelephoneDefaultOptions>('CORE_SAISIE_TELEPHONE_DEFAULT_OPTIONS');

@Component({
  selector: 'core-saisie-telephone',
  standalone: true,
  imports: [
    MatInputModule,
    CodeToFlagEmoji,
    MatOptionModule,
    MatAutocompleteModule
  ],
  providers: [{provide: MatFormFieldControl, useExisting: SaisieTelephoneComponent}],
  templateUrl: './saisie-telephone.component.html',
  styleUrl: './saisie-telephone.component.css'
})
export class SaisieTelephoneComponent implements MatFormFieldControl<{
  indicatif: string | null,
  telephone: string | null
}>, OnDestroy {

  @Input()
  public partieIndicatifPlaceholder: string | null = null;

  @Input()
  public partieTelephonePlaceholder: string | null = null;

  get placeholder(): string {
    return this._placeholder;
  }

  @Input()
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  get indicatifParDefaut(): string|null {
    return this._indicatifParDefaut;
  }

  @Input()
  set indicatifParDefaut(value: string) {
    this._indicatifParDefaut = value;
    this.stateChanges.next();
  }

  get indicatif(): string | null {
    return this._indicatif;
  }

  @Input()
  set indicatif(value: string | null) {
    this._indicatif = value;
    this.stateChanges.next();
  }

  get telephone(): string | null {
    return this._telephone;
  }

  @Input()
  set telephone(value: string | null) {
    this._telephone = value;
    this.stateChanges.next();
  }

  private _choixIndicatifs: IndicatifTelephoniqueResource[] = [];
  private valeurInitialeTelephoneALaPriseDeFocus: string | null = null;
  private valeurInitialeIndicatifALaPriseDeFocus: string | null = null;

  get choixIndicatifs(): IndicatifTelephoniqueResource[] {
    return this._choixIndicatifs;
  }

  @Input({required: true})
  set choixIndicatifs(value: IndicatifTelephoniqueResource[]|null) {
    this._choixIndicatifs = [...(value ?? [])].sort((a, b) => a.indicatifTelephonique.localeCompare(b.indicatifTelephonique));
    // mettre l'indicatif par défaut à la première position
    const index = this._choixIndicatifs.findIndex(item => item.indicatifTelephonique == this._indicatifParDefaut);
    if (index !== -1) {
      const [found] = this._choixIndicatifs.splice(index,1);
      this._choixIndicatifs.unshift(found);
    }
    this.stateChanges.next();
  }

  @ViewChild('indicatifInput')
  private indicatifInput?: MatInput;

  @ViewChild('telephoneInput')
  private telephoneInput?: MatInput;

  private _telephone: string | null = null;
  private _indicatif: string | null = this.indicatifParDefaut;

  @Output() utilisateurAModifieTelephone = new EventEmitter<string | null>();
  @Output() utilisateurAModifieIndicatif = new EventEmitter<string | null>();
  @Output() utilisateurAInteragisAvecIndicatif = new EventEmitter<'dirty' | 'touched'>();
  @Output() utilisateurAInteragisAvecTelephone = new EventEmitter<'dirty' | 'touched'>();


  constructor(@Optional() @Inject(CORE_SAISIE_TELEPHONE_DEFAULT_OPTIONS) protected defaultOptions: SaisieTelephoneDefaultOptions | null) {
  }

  onUtilisateurASaisieUnIndicatif($event: Event) {
    this.utilisateurAModifieIndicatif.emit((<HTMLInputElement>$event.target).value);
    this.stateChanges.next();
  }

  onUtilisateurAInputTelephone($event: Event) {
    this.utilisateurAModifieTelephone.emit((<HTMLInputElement>$event.target).value);
    this.stateChanges.next();

  }

  onUtilisateurAPrisFocusSurTelephone($event: FocusEvent) {
    this.valeurInitialeTelephoneALaPriseDeFocus = ($event.target as HTMLInputElement).value;
    this.focused = true;
    this.stateChanges.next();

  }

  onUtilisateurABlurFocusSurTelephone($event: FocusEvent) {
    this.utilisateurAInteragisAvecTelephone.emit(this.valeurInitialeTelephoneALaPriseDeFocus !== ($event.target as HTMLInputElement).value ? 'dirty' : 'touched');
    this.focused = false;
    this.stateChanges.next();
  }

  onUtilisateurAPrisFocusSurIndicatif(matInput: MatInput) {
    this.valeurInitialeIndicatifALaPriseDeFocus = matInput.value;
    this.focused = true;
    this.stateChanges.next();

  }

  onUtilisateurABlurFocusSurIndicatif(matInput: MatInput) {
    this.utilisateurAInteragisAvecIndicatif.emit(this.valeurInitialeIndicatifALaPriseDeFocus !== matInput.value ? 'dirty' : 'touched');
    this.focused = false;
    this.stateChanges.next();
  }


  //#region Implémentation de MatFormFieldControl, cf. https://material.angular.io/guide/creating-a-custom-form-field-control

  /**
   * Récupération de la valeur correspondant à l'implémentation de MatFormFieldControl.
   */
  public get value(): { indicatif: string | null; telephone: string | null; } | null {
    return this._indicatif == null && this._telephone == null ? null : {
      indicatif: this._indicatif,
      telephone: this._telephone
    };
  }

  /**
   * Affectation de la valeur correspondant à l'implémentation de MatFormFieldControl.
   * @param value valeur à attribuer au champs
   */
  public set value(value: { indicatif: string | null; telephone: string | null; } | null) {
    this._indicatif = value?.indicatif ?? null;
    this._telephone = value?.telephone ?? null;
  }

  /**
   * Sujet qui doit émettre une notification lorsque n'importe laquelle des valeurs dont dépend MatFormfieldcontrol a été modifiée.
   * MatFormFieldControl étant en OnPush, il ne peut pas détecter les changements de valeurs de ses propriétés.
   */
  stateChanges = new Subject<void>();

  static nextId = 0;
  /**
   * Identifiant unique du composant courant pour MatFormFieldControl. Utilisé pour lier les labels et hints.
   */
  @HostBinding() id = `core-saisie-telephone-${SaisieTelephoneComponent.nextId++}`;


  private _placeholder: string = '';
  private _indicatifParDefaut: string|null = '';

  /**
   * Permet de lier le composant à un NgControl, pour l'implémentation de ControlValueAccessor.
   * Pas implémenté pour le moment car on n'utilise de toute façon pas les CVA
   * cf. https://material.angular.io/guide/creating-a-custom-form-field-control#ngcontrol )
   */
  ngControl: NgControl | AbstractControlDirective | null = null;

  /**
   * Propriété booléen indiquant si le composant est en focus ou non.
   * Si le composant a le focus, MatFormField ajoute un trait solide en dessous du label.
   */
  focused: boolean = false;

  /**
   * Propriété booléen indiquant si le composant est vide ou non
   */
  get empty(): boolean {
    return (this.indicatif == null || this.indicatif == '') && (this.telephone == null || this.telephone == '');
  }

  /**
   * Propriété booléen indiquant si le label doit flotter ou non.
   * Le label doit flotter si le composant est en focus ou si le composant n'est pas vide.
   * cf. https://material.angular.io/guide/creating-a-custom-form-field-control#shouldlabelfloat
   * On en profite pour ajouter une classe sur le composant hôte afin de pouvoir styliser le composant lorsque le label est flottant ou non.
   */
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  /**
   * Propriété indiquant si le champ est requis ou non
   */
  @Input()
  get required() {
    return this._required;
  }

  set required(req: boolean) {
    this._required = req;
    this.stateChanges.next();
  }

  private _required = false;

  /**
   * Propriété indiquant si le champ est désactivé ou non
   */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
    this.stateChanges.next();
  }

  private _disabled = false;

  /**
   * Propriété permettant d'indiquer si le champ est en erreur ou non, exigée par MatFormFieldControl
   * comme nous utilisons notre propre gestion d'erreur directement sur le model et non sur l champ, on ne considère jamais le champs comme intrinséquemment en erreur.
   */
  errorState: boolean = false;

  /**
   * Type de contrôle
   * Une classe CSS est automatiquement appliquée sur le matformfield
   * Dans notre cas, il devrait s'agir de la classe mat-form-field-type-core-saisie-telephone
   * cf. https://material.angular.io/guide/creating-a-custom-form-field-control#shouldlabelfloat
   */
  controlType: string = 'core-saisie-telephone';

  /**
   * TODO : décrire ce à quoi sert cette méthode
   * cf. https://material.angular.io/guide/creating-a-custom-form-field-control#setdescribedbyidsids-string
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDescribedByIds(ids: string[]): void {
  }

  /**
   * Méthode appelée à chaque fois que le mat-form-field conteneur est cliqué
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onContainerClick(event: MouseEvent): void {
    if (!this.shouldLabelFloat) {
      this.indicatifInput!.focus();
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  onUtilisateurASelectionneUnIndicatif($event: MatAutocompleteSelectedEvent) {
    this.utilisateurAModifieIndicatif.emit($event.option.value);
    this.stateChanges.next();
    setTimeout(() => {
      this.telephoneInput!.focus();
    });
  }

  getPaysFiltres() {
    if (this.indicatif == null || this.indicatif.trim() == '')
      return this.choixIndicatifs;

    return this.choixIndicatifs.filter(f => f.indicatifTelephonique.indexOf(this.indicatif!) !== -1);
  }

  getPremierDrapeauCorrespondantAIndicatif() {
    const pays = this.choixIndicatifs.find(f => f.indicatifTelephonique == this.indicatif);
    return pays?.iso2 ?? '';
  }
}
