/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Directive,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  forwardRef
} from '@angular/core';
import {InteractionStatus, ModelDirective} from '@core/forms/model.directive';
import {FieldModelStatus, getNodeErrorMessage, getNodeLockMessage} from '@core/validation/model.validation';
import {ModelFieldDirectiveBase} from '@core/forms/model-field.directive';
import {MatTooltip} from '@angular/material/tooltip';
import {
  BehaviorSubject, ReplaySubject, Subject, Subscription, combineLatest, first, switchMap, tap, scan
} from 'rxjs';
import {CaptchaComponent} from "@core/captcha/captcha.component";


@Directive({
  selector: 'core-captcha[coreModelField]',
  providers: [
    {provide: ModelFieldDirectiveBase, useExisting: forwardRef(() => CaptchaFormFieldDirective)},
    {provide: MatTooltip, useClass: MatTooltip}
  ],
  standalone: true
})
export class CaptchaFormFieldDirective extends ModelFieldDirectiveBase<any> implements OnInit, AfterViewInit, OnDestroy {
  @Input('coreModelField')
  set fieldName(value: string) {
    this.fieldNames = [value];
  }

  private fieldStatus$: Subject<FieldModelStatus<any>> = new ReplaySubject<FieldModelStatus<any>>(1);
  private fieldValue$: Subject<any> = new ReplaySubject<any>(1);
  private interactionStatus$: Subject<InteractionStatus | null> = new BehaviorSubject<InteractionStatus | null>(null);
  private afterViewInit$: Subject<boolean> = new ReplaySubject<boolean>(1);
  private subscription?: Subscription;


  constructor(parent: ModelDirective<any>,
              private captchaComponent: CaptchaComponent,
              private vcr: ViewContainerRef,
              private tooltip: MatTooltip) {
    super(parent);
  }

  ngAfterViewInit(): void {
    this.afterViewInit$.next(true);
  }

  ngOnInit(): void {
    super.onInit();

    const applyFieldStatus = combineLatest([
      this.fieldStatus$,
      this.interactionStatus$
    ]).pipe(
      switchMap(([fieldStatus, interactionStatus]) => {

        const errorMessages = getNodeErrorMessage(fieldStatus);
        const lockMessages = getNodeLockMessage(fieldStatus);

        const allMessages = errorMessages.concat(lockMessages).filter((value, index, self) => self.indexOf(value) === index);
        if (allMessages.length > 0) {
          this.tooltip.message = allMessages.join('\n');
        } else {
          //Remove error css class
          this.tooltip.message = '';
        }
        return this.afterViewInit$.pipe(
          first(),
          tap(() => {
            //Add error css class
            const element: HTMLElement = this.vcr.element.nativeElement;
            this.syncStatusCssClassesOnDOMElement(element, errorMessages.length > 0, lockMessages.length > 0);
            this.syncInteractionStatusCssClassesOnDOMElement(element, interactionStatus);
          })
        );
      })
    );

    const registerToFieldChanged = this.afterViewInit$.pipe(
      first(),
      switchMap(() => {
        return this.captchaComponent.captchaResoluOuExpire;
      }),
      scan((previousValue, newValue) => {
        this.onFieldValueChanged(newValue);
        this.onUserTouched();
        this.onUserInteracted({before: previousValue, after: newValue});
        return newValue;
      }, '' as (string|null))
    );


    this.subscription = combineLatest([
      applyFieldStatus, registerToFieldChanged
    ]).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    super.onDestroy();
  }

  @HostListener('mouseover') mouseover() {
    this.tooltip.show();
  }

  @HostListener('click') touched() {
    this.tooltip.show();
  }

  @HostListener('mouseleave') mouseleave() {
    this.tooltip.hide();
  }

  applyFieldStatus(fieldStatus: FieldModelStatus<any>): void {
    this.fieldStatus$.next(fieldStatus);
  }

  applyFieldValue(fieldValue: any): void {
    this.fieldValue$.next(fieldValue);
  }

  override applyInteractionStatus(interactionStatus: InteractionStatus): void {
    this.interactionStatus$.next(interactionStatus);
  }
}


