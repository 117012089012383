/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Directive, ElementRef,
  forwardRef, HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import {MatInput} from "@angular/material/input";
import {ModelFieldDirectiveBase} from "@core/forms/model-field.directive";
import {MatTooltip} from "@angular/material/tooltip";
import {InteractionStatus, ModelDirective} from "@core/forms/model.directive";
import {
  BehaviorSubject,
  combineLatest, filter,
  first, fromEvent, map, Observable,
  ReplaySubject,
  Subject,
  Subscription,
  switchMap,
  tap
} from "rxjs";
import {FieldModelStatus, getNodeErrorMessage, getNodeLockMessage} from "@core/validation/model.validation";

@Directive({
  selector: 'mat-form-field[coreModelField][matInput]',
  standalone: true,
  providers:[
    { provide: ModelFieldDirectiveBase, useExisting: forwardRef(() => MatInputFormFieldDirective) },
    { provide: MatTooltip, useClass: MatTooltip }
  ]
})
export class MatInputFormFieldDirective extends ModelFieldDirectiveBase<any> implements OnInit, AfterViewInit, OnDestroy {
  @Input('coreModelField')
  public set fieldName(value: string) {
    this.fieldNames=[value];
  }


  @Input()
  matInput!: MatInput;

  @Input()
  mode : 'ChangeOnBlur'|'ChangeOnInput'='ChangeOnBlur';

  private fieldStatus$: Subject<FieldModelStatus<any>> = new ReplaySubject<FieldModelStatus<any>>(1);
  private fieldValue$: Subject<any> = new ReplaySubject<any>(1);
  private interactionStatus$: Subject<InteractionStatus|null> = new BehaviorSubject<InteractionStatus|null>(null);
  private afterViewInit$: Subject<boolean> = new ReplaySubject<boolean>(1);
  private subscription?: Subscription;


  constructor(parent: ModelDirective<any>,
              private vcr: ViewContainerRef,
              private tooltip: MatTooltip) {
    super(parent);
  }

  ngAfterViewInit(): void {
    this.afterViewInit$.next(true);
  }

  ngOnInit(): void {
    super.onInit();

    const applyFieldValue = this.fieldValue$.pipe(
      tap(fieldValue=>{
          this.matInput.value = fieldValue;
      })
    );

    const applyFieldStatus = combineLatest([
      this.fieldStatus$,
      this.interactionStatus$
    ]) .pipe(
      switchMap(([fieldStatus, interactionStatus]) => {

        const errorMessages = getNodeErrorMessage(fieldStatus);
        const lockMessages = getNodeLockMessage(fieldStatus);

        const allMessages = errorMessages.concat(lockMessages).filter((value, index, self) => self.indexOf(value) === index);
        if (allMessages.length > 0) {
          this.tooltip.message = allMessages.join('\n');
        }
        else {
          //Remove error css class
          this.tooltip.message = '';
        }

        return this.afterViewInit$.pipe(
          first(),
          tap(()=>{
            //Add error css class
            const element : HTMLElement = this.vcr.element.nativeElement;
            const matForFieldParent = element.closest('mat-form-field');

            this.syncStatusCssClassesOnDOMElement(matForFieldParent, errorMessages.length > 0, lockMessages.length > 0);
            this.syncInteractionStatusCssClassesOnDOMElement(matForFieldParent, interactionStatus);
          })
        );
      })
    );

    const registerToFieldChanged = this.afterViewInit$.pipe(
      first(),
      switchMap(()=>{
        return getMatInputUserChanged(this.matInput, this.mode);
      }),
      tap((value) => {
        this.onFieldValueChanged(value);
      }));


    const registerToTouched = this.afterViewInit$.pipe(
      first(),
      switchMap(()=>{
          return getMatInputUserBlurred(this.matInput);
      }),
      tap(() => {
        this.onUserTouched();
      }));

    const registerToInteracted = this.afterViewInit$.pipe(
      first(),
      switchMap(()=>{
        return getMatInputUserHasFocusedThenBlurredValue(this.matInput);
      }),
      tap((values) => {
        this.onUserInteracted(values);
      }));

    this.subscription = combineLatest([
      applyFieldStatus, applyFieldValue,registerToFieldChanged, registerToTouched,registerToInteracted
    ]).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    super.onDestroy();
  }

  @HostListener('mouseover') mouseover() {
    this.tooltip.show();
  }

  @HostListener('click') touched() {
    this.tooltip.show();
  }

  @HostListener('mouseleave') mouseleave() {
    this.tooltip.hide();
  }

  applyFieldStatus(fieldStatus: FieldModelStatus<any>): void {
    this.fieldStatus$.next(fieldStatus);
  }

  applyFieldValue(fieldValue: any): void {
    this.fieldValue$.next(fieldValue);
  }

  override applyInteractionStatus(interactionStatus: InteractionStatus): void {
    this.interactionStatus$.next(interactionStatus);
  }
}



export function getMatInputUserChanged(matInput:MatInput, mode: 'ChangeOnBlur'|'ChangeOnInput') : Observable<any>{

  if(mode === 'ChangeOnBlur'){
    return getMatInputUserHasFocusedThenBlurredValue(matInput).pipe(
      filter(({before, after})=>before!=after),
      map(({after})=>after)
    )
  }
  else
    return getMatInputUserHasInputValue(matInput);
}


export function getMatInputUserHasFocusedThenBlurredValue(matInput:MatInput) : Observable<{before:any,after: any}>{
  if((matInput.type === 'text' || matInput.type === 'email')){
    const elementRef : ElementRef = (matInput as any)._elementRef;
    return fromEvent(elementRef.nativeElement as HTMLInputElement, 'focus').pipe(
      map(()=>(elementRef.nativeElement as HTMLInputElement).value),
      switchMap((initialValue)=>{
        return fromEvent(elementRef.nativeElement as HTMLInputElement, 'blur').pipe(
          map(()=>(elementRef.nativeElement as HTMLInputElement).value),
          map(value=>({before:initialValue, after:value})),
          first()
        );
      })
    );
  }

  throw new Error(`Le type ${matInput.type} n'est pas implémenté dans getMatInputUserChanged`);
}


export function getMatInputUserBlurred(matInput:MatInput) : Observable<any>{
  if(matInput.type === 'text' || matInput.type === 'email'){
    const elementRef : ElementRef = (matInput as any)._elementRef;
    return fromEvent(elementRef.nativeElement as HTMLInputElement, 'blur');
  }

  throw new Error(`Le type ${matInput.type} n'est pas implémenté dans getMatInputUserBlurred`);
}

export function getMatInputUserHasInputValue(matInput:MatInput) : Observable<any>{
  if(matInput.type === 'text' || matInput.type === 'email'){
    const elementRef : ElementRef = (matInput as any)._elementRef;
    return fromEvent(elementRef.nativeElement as HTMLInputElement, 'input').pipe(
      map(()=>(elementRef.nativeElement as HTMLInputElement).value),
    );
  }

  throw new Error(`Le type ${matInput.type} n'est pas implémenté dans getMatInputUserHasInputValue`);
}
