/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Directive,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  forwardRef
} from '@angular/core';
import {InteractionStatus, ModelDirective} from '@core/forms/model.directive';
import {FieldModelStatus, getNodeErrorMessage, getNodeLockMessage} from '@core/validation/model.validation';
import {ModelFieldDirectiveBase} from '@core/forms/model-field.directive';
import {MatTooltip} from '@angular/material/tooltip';
import {
  ReplaySubject, Subject, Subscription, combineLatest, first, switchMap, tap
} from 'rxjs';
import {SelectionPaysComponent} from "@core/material-ui/components/selection-pays/selection-pays.component";

@Directive({
// eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-form-field[coreModelField][selectionPays]',
  providers: [
    {provide: ModelFieldDirectiveBase, useExisting: forwardRef(() => SelectionPaysFormFieldDirective)},
    {provide: MatTooltip, useClass: MatTooltip}
  ],
  standalone: true
})
export class SelectionPaysFormFieldDirective extends ModelFieldDirectiveBase<any> implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  set coreModelField(value: string) {
    this.fieldNames = [value];
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('selectionPays')
  component!: SelectionPaysComponent;

  private fieldStatus$: Subject<FieldModelStatus<any>> = new ReplaySubject<FieldModelStatus<any>>(1);
  private fieldValue$: Subject<any> = new ReplaySubject<any>(1);
  private interactionStatus$: Subject<InteractionStatus> = new ReplaySubject<InteractionStatus>(1);
  private afterViewInit$: Subject<boolean> = new ReplaySubject<boolean>(1);
  private subscription?: Subscription;

  constructor(parent: ModelDirective<any>,
              private vcr: ViewContainerRef,
              private tooltip: MatTooltip) {
    super(parent);
  }

  ngAfterViewInit(): void {
    this.afterViewInit$.next(true);
  }

  ngOnInit(): void {
    super.onInit();

    const applyFieldValue = this.fieldValue$.pipe(
      tap(fieldValue => {
        this.component.paysSelectionneISO2 = fieldValue;
      })
    );

    const applyFieldStatus = combineLatest([
      this.fieldStatus$,
      this.interactionStatus$
    ]).pipe(
      switchMap(([fieldStatus, interactionStatus]) => {

        const errorMessages = getNodeErrorMessage(fieldStatus);

        const lockMessages = getNodeLockMessage(fieldStatus);

        const allMessages = errorMessages.concat(lockMessages).filter((value, index, self) => self.indexOf(value) === index);

        if (allMessages.length > 0) {
          this.tooltip.message = allMessages.join('\n');
        } else {
          //Remove error css class
          this.tooltip.message = '';
        }

        return this.afterViewInit$.pipe(
          first(),
          tap(() => {
            //Add error css class
            const element: HTMLElement = this.vcr.element.nativeElement;
            const matForFieldParent = element.closest('mat-form-field');
            this.syncStatusCssClassesOnDOMElement(matForFieldParent, errorMessages.length > 0, lockMessages.length > 0);
            this.syncInteractionStatusCssClassesOnDOMElement(matForFieldParent, interactionStatus);
          })
        );
      })
    );

    const registerToFieldChanged =

      this.afterViewInit$.pipe(
        first(),
        switchMap(() => {
          return this.component.utilisateurASelectionnePaysISO2;
        }),
        tap((value) => {
          this.onFieldValueChanged(value);
        }));


    const registerToInteracted =
      this.afterViewInit$.pipe(
        first(),
        switchMap(() => {
          return this.component.utilisateurAInteragis;
        }),
        tap((values) => {
          this.onUserTouched();
          this.parent.userInteracted.next({fieldName: this.fieldNames[0], interaction: values});
        }));


    this.subscription = combineLatest([
      applyFieldStatus, applyFieldValue, registerToFieldChanged, registerToInteracted
    ]).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    super.onDestroy();
  }

  @HostListener('mouseover') mouseover() {
    this.tooltip.show();
  }

  @HostListener('click') touched() {
    this.tooltip.show();
  }

  @HostListener('mouseleave') mouseleave() {
    this.tooltip.hide();
  }

  applyFieldStatus(fieldStatus: FieldModelStatus<any>): void {
    this.fieldStatus$.next(fieldStatus);
  }

  applyFieldValue(fieldValue: any): void {
    this.fieldValue$.next(fieldValue);
  }

  override applyInteractionStatus(interactionStatus: InteractionStatus): void {
    this.interactionStatus$.next(interactionStatus);
  }
}


