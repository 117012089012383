import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {LayoutComponent} from './layout/layout.component';
import {provideState, provideStore} from '@ngrx/store';
import {metaReducers, reducers} from './store/way-state';
import {provideEffects} from '@ngrx/effects';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {polyfillCountryFlagEmojis} from 'country-flag-emoji-polyfill';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {ContactBottomSheetComponent} from '@core/components/tds/contact-bottom-sheet/contact-bottom-sheet.component';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {CodeToFlagEmoji} from '@core/helpers/flags.helpers';
import {HttpClientModule} from "@angular/common/http";
import {provideBaseApiUrl} from '@core/backend/services/base-api.url';
import {BehaviorSubject, Observable} from "rxjs";
import {IS_MOBILE$, isUserUsingMobile} from "@core/helpers/mobile-management";
import {CoreModule} from "@core/core.module";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {LetDirective} from "@ngrx/component";
import * as ouvrirPopupParametrageDebogage from "@way/app/parametrage-debogage-popup/store/effects/ouvrir-popup-parametrage-debogage.effect";
import * as gestionErreurEffect from "@way/app/store/effects/gestion-erreur.effect";
import * as gererScrollEffect from "@way/app/store/effects/gerer-scroll.effect";
import * as chargerInfoBrokerEffect from "@way/app/store/effects/charger-info-broker.effect";
import { EventLoggerService, LogErrorHandler } from "@core/errors/logErrorHandler";
import { MatBadgeModule } from "@angular/material/badge";
import { GoogleTagManagerConfiguration } from "angular-google-tag-manager";
import {
  CORE_SAISIE_TELEPHONE_DEFAULT_OPTIONS, SaisieTelephoneDefaultOptions
} from "@core/material-ui/components/saisie-telephone/saisie-telephone.component";
import {
  CORE_SAISIE_DATE_DEFAULT_OPTIONS,
  SaisieDateDefaultOptions
} from "@core/material-ui/components/saisie-date/saisie-date.component";
import {RECAPTCHA_LOADER_OPTIONS} from "ng-recaptcha";
import {RecaptchaLoaderOptions} from "ng-recaptcha/lib/tokens";
import {globalFeature} from "@way/app/store/global.feature";
import {parametrageDeboggageServeurFeature} from "@way/app/parametrage-debogage-popup/store/parametrage-deboggage-serveur.feature";
import {layoutFeature} from "@way/app/layout/store/layout.feature";
import {CORE_CAPTCHA_DEFAULT_OPTIONS, CoreCaptchaDefaultOptions} from "@core/captcha/captcha.component";
import {
  CORE_DALENYS_DEFAULT_CONFIGURATION,
  CoreDalenysDefaultConfiguraton
} from "@core/payment/dalenys-client-side-encryption.service";
import {provideDemandeRappel} from "@core/components/tds/demande-rappel/provide-demande.rappel";
import {souscriptionFeature} from "@way/app/souscription/store/souscription.feature";
import {devisMonTarifFeature} from "@way/app/devis/mon-tarif/store/mon-tarif.feature";
import {provideMentionsLegales} from "@core/components/tds/mentions-legales/provide-mentions-legales.options";
import {
  ChargerReferentielAuDemarrageDeLapplicationFactory
} from "@core/referentiel/charger-referentiel-au-demarrage-de-l-application.factory";
import { provideCGU } from '@core/components/tds/cgu/provide-cgu.options';


const telephoneDefaultOptions: SaisieTelephoneDefaultOptions = {
  placeholders: {
    indicatif: $localize`:@@saisie_telephone_indicatif:Indicatif`,
    telephone: $localize`:@@saisie_telephone_numero:Numéro`
  }
}
const saisieDateDefaultOptions  : SaisieDateDefaultOptions={
  placeholder:$localize`:@@saisie_date_placeholder:JJ/MM/AAAA`,
}

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    BrowserModule,
    ContactBottomSheetComponent,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatBottomSheetModule,
    MatListModule,
    MatMenuModule,
    CodeToFlagEmoji,
    MatSlideToggleModule,
    LetDirective,
    MatBadgeModule,
  ],
  exports: [
    CoreModule
  ],
  providers: [
    provideStore(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionTypeUniqueness: true,
        strictActionWithinNgZone: true
      }
    }),
    provideState(globalFeature),
    provideState(parametrageDeboggageServeurFeature),
    provideState(layoutFeature),
    provideEffects([
      //Liste des effects globaux de l'application WAY
      ouvrirPopupParametrageDebogage,
      gestionErreurEffect,
      gererScrollEffect,
      chargerInfoBrokerEffect
    ]),
    {
      provide: 'Window',
      useValue: window
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: ChargerReferentielAuDemarrageDeLapplicationFactory,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        return EventLoggerService.Init
      },
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        return () => {
          polyfillCountryFlagEmojis()
        };
      }
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        return () => {

          // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
          let vh = window.innerHeight * 0.01;
          // Then we set the value in the --vh custom property to the root of the document
          document.documentElement.style.setProperty('--vh', `${vh}px`);

          window.addEventListener('resize', () => {
            // We execute the same script as before
            vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
          });
        };
      }
    },

    {
      provide: APP_INITIALIZER,
      deps: [GoogleTagManagerConfiguration],
      useFactory: (
        configuration: GoogleTagManagerConfiguration
      ) => {
        return ()=> {
          if (runtimeEnv.APP_ENV != 'dev') {
            configuration.set({
              id: runtimeEnv.GOOGLE_TAG_MANAGER_ID
            })
          }
        };
      },
      multi: true
    },
    provideBaseApiUrl(runtimeEnv.API_BASE_URL),

    {
      provide: IS_MOBILE$,
      useFactory: () => {
        const subject = new BehaviorSubject<boolean>(isUserUsingMobile());
        window.onresize = () => {
          setTimeout(() => {

            const isUserOnMobile = isUserUsingMobile();
            subject.next(isUserOnMobile);
          });
        };

        return subject;
      }
    },
    {provide: ErrorHandler, useClass: LogErrorHandler},
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [IS_MOBILE$],
      useFactory: (isMobile$: Observable<boolean>) => {
        return () => {
          isMobile$.subscribe((isMobile) => {
            if (isMobile) {
              window.document.body.classList.add('is-mobile');
            } else {
              window.document.body.classList.remove('is-mobile');
            }
          })
        }
      }
    },
    {provide: CORE_SAISIE_TELEPHONE_DEFAULT_OPTIONS, useValue: telephoneDefaultOptions},
    {provide: CORE_SAISIE_DATE_DEFAULT_OPTIONS, useValue:saisieDateDefaultOptions},
    {provide: CORE_CAPTCHA_DEFAULT_OPTIONS, useValue: ({recaptchaSiteKey: runtimeEnv.GOOGLE_RECAPTCHA_PUBLIC_KEY} as CoreCaptchaDefaultOptions)},
    {provide: CORE_DALENYS_DEFAULT_CONFIGURATION, useValue:({tokenizeUrl:runtimeEnv.DALENYS_CONFIG_TOKENIZE_URL, apiPublicKey: runtimeEnv.DALENYS_CONFIG_API_KEY, cseKeysUrl: runtimeEnv.DALENYS_CONFIG_CSE_KEYS_URL} as  CoreDalenysDefaultConfiguraton)},
    ...provideDemandeRappel({
      selectors:{
        selectNomCommercial: globalFeature.selectNomCommercial,
        selectDevisId: devisMonTarifFeature.selectDevisId,
        selectSouscriptionEnCours: souscriptionFeature.selectSouscriptionEnCours,
        selectIndicatifsTelephoniques: globalFeature.selectIndicatifsTelephoniques,
      },
    }),
    provideMentionsLegales({
      codeProduitPourTelechargementFichier:'IWAYP'
    }),
    provideCGU({
      nomSite: 'neoyouth'
    }),
    {
      provide: RECAPTCHA_LOADER_OPTIONS,
      useFactory: (locale: string) => {
        const result : RecaptchaLoaderOptions = ({
          onBeforeLoad(url: URL) {
            //On applique la locale au recaptcha
            url.searchParams.set("hl", locale);
            return {url};
          },
        });
        return result;
      },
      deps: [LOCALE_ID],
    }
  ],
  bootstrap: [LayoutComponent],
})
export class AppModule {
}




