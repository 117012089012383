import {DevisResource, ParametrageWayRessource} from "@core/backend/models/devis";

export interface DevisMonTarifFeatureState{
  monTarif: DevisMonTarifState;
}

export interface DevisMonTarifState{
  devis: DevisResource<ParametrageWayRessource> | null;
}

export class StateFactory{

  public static createInitialDevisState():DevisMonTarifFeatureState{
    return {
      monTarif:{
        devis: null
      },
    }
  }
}


