import {InjectionToken} from "@angular/core";

export interface CGUOptions {
  nomSite:string;
}


export const CORE_CGU_OPTIONS = new InjectionToken<CGUOptions>('CORE_CGU_OPTIONS');

export function provideCGU(options: CGUOptions) {
  return {provide: CORE_CGU_OPTIONS, useValue: options};
}
