import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CORE_CGU_OPTIONS } from './provide-cgu.options';

@Component({
  selector: 'core-cgu',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './cgu.component.html',
  styleUrl: './cgu.component.scss'
})
export class CguComponent {

  protected readonly nomSiteActuel = inject(CORE_CGU_OPTIONS).nomSite;

  public goBack(): void {
    history.back()
  }
}
